import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const availableLanguages = [`en`, `es`, `nl`]

// console.log({ i18n })

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false, // we do not use keys in form messages.welcome
  })

export { i18n }
