import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { languageSyncMiddleware } from './language-sync-middleware'
import appReducer from './actions/app'

export const store = configureStore({
  reducer: { app: appReducer },
  middleware: [languageSyncMiddleware],
})
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store['getState']>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
