import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistStateReducer } from '../../persistence'

export enum ScanningSetting {
  CAMERA = 'CAMERA',
  KEYBOARD = 'KEYBOARD',
  SCANNER = 'SCANNER',
}

const initialState = { scanning: ScanningSetting.CAMERA }

export const slice = createSlice({
  name: 'scanning',
  initialState,
  reducers: {
    setScanning: (state, action: PayloadAction<ScanningSetting>) => {
      state.scanning = action.payload
    },
  },
})

export const { setScanning } = slice.actions

export const scanSettingToDisplayString = (
  setting: ScanningSetting
): string => {
  switch (setting) {
    case ScanningSetting.CAMERA:
      return 'Camera'

    case ScanningSetting.SCANNER:
      return 'Barcode Scanner'

    case ScanningSetting.KEYBOARD:
      return 'Keyboard'

    default:
      return 'Unknown'
  }
}

export default persistStateReducer(`app-scanning`, slice.reducer)
