import { Middleware } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import localeReducer, { setLocale } from './actions/app/locale'
import { i18n } from '../lib/translations/i18n'

export const languageSyncMiddleware: Middleware = () => {
  return (next) => (action) => {
    if (setLocale.match(action)) {
      if (i18n.language !== action.payload) {
        i18n.changeLanguage(action.payload)
      }
    }

    if (
      action.type === REHYDRATE &&
      action.key === `app-locale` &&
      action.payload
    ) {
      const localState = action.payload as ReturnType<typeof localeReducer>
      i18n.changeLanguage(localState.selected)
    }

    next(action)
  }
}
