import { ITelemetryItem } from '@microsoft/applicationinsights-web'

abstract class TelemetryInitializerBase {
  abstract process(item: ITelemetryItem): boolean | void
}

export class AppTelemetryInitializer extends TelemetryInitializerBase {
  process(item: ITelemetryItem): boolean | void {
    item.tags!['ai.cloud.role'] = 'Work App'
    item.tags!['ai.cloud.roleInstance'] = window.location.origin
  }
}
