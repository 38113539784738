import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";


export const createAppInsightsInstance = (instrumentationKey: string) => {

  const iKey = instrumentationKey
  console.log("iKey",iKey)

  const appInsights = new ApplicationInsights({
    config: {
     // endpointUrl: aiproxyEndpoint,
      instrumentationKey: iKey,
      disableTelemetry: false,
      maxBatchSizeInBytes: 1000000,
      maxBatchInterval: 0,
      autoTrackPageVisitTime: true,
      disableFetchTracking: false,
      enableCorsCorrelation: true,
      enableUnhandledPromiseRejectionTracking: true,
    //  correlationHeaderExcludedDomains: ['lr-in-prod.com'],
    },
  });

  /*
  appInsights.addTelemetryInitializer((envelope) => {
    const telemetryItem = envelope.data![0] as ITelemetryItem;
    if (telemetryItem && telemetryItem.baseData && telemetryItem.baseType === "Dependency") {
      console.log("Dependency")
      telemetryItem.baseData.properties = {
        ...telemetryItem.baseData.properties,
        corsAllowed: true,
        "Access-Control-Allow-Origin": "*",
      };
    }
  });
*/
  appInsights.loadAppInsights();

  return appInsights;
};


/*

import {
  ApplicationInsights,
  IApplicationInsights,
  IConfig,
  IConfiguration,
} from '@microsoft/applicationinsights-web'

export const createClient = (aiproxyEndpoint: string): IApplicationInsights => {
  const config: IConfiguration & IConfig = {
    autoTrackPageVisitTime: true,
    disableFetchTracking: false,
    enableCorsCorrelation: true,
    enableUnhandledPromiseRejectionTracking: true,
    endpointUrl: aiproxyEndpoint,
    instrumentationKey: aiproxyEndpoint,
  }

  const appInsights = new ApplicationInsights({ config })
  appInsights.loadAppInsights()

  return appInsights
}
*/