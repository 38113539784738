import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistStateReducer } from '../../persistence'

export const slice = createSlice({
  name: 'context',
  initialState: {
    companyId: ``,
    resourceId: ``,
    isResourceConfirmed: false,
    isSelectingResource: false,
    isAdmin: false
  },
  reducers: {
    setCompany: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload
      state.resourceId = state.isAdmin ? `` : state.resourceId
      state.isResourceConfirmed = false
      state.isSelectingResource = true
    },
    setResource: (state, action: PayloadAction<string>) => {
      state.resourceId = action.payload
      state.isResourceConfirmed = true
      state.isSelectingResource = false
    },
    setAdmin: (state) => {
      state.isAdmin = true
    },
    showResourceDialog: (state) => {
      if(state.isAdmin){
        state.isSelectingResource = true
      }
    },
    closeResourceDialog: (state) => {
      state.isSelectingResource = false
    },
    expectsResource: (state) => {
      if (!state.isResourceConfirmed && !state.resourceId && state.isAdmin) {
        state.isSelectingResource = true
      }
    },
  },
})

export const {
  setCompany,
  setResource,
  closeResourceDialog,
  showResourceDialog,
  expectsResource,
  setAdmin
} = slice.actions

export default persistStateReducer(`app-context`, slice.reducer)
