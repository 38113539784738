import {
  AppBar,
  Box,
  Button,
  NoSsr,
  Toolbar,
  Typography
} from '@material-ui/core'
import { MpriseGatewayApolloClient } from '@mprise/gateway-client'
import { MpriseAuthProvider, MpriseIdentityServer } from '@mprise/react-auth'
import {
  Alerts,
  MpriseMuiThemeProvider,
  MpriseTheme,
  MText
} from '@mprise/react-ui'
import { buildClientSchema } from 'graphql'
import { enableMapSet } from 'immer'
import React, { ErrorInfo, Suspense, useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/lib/integration/react'
import GatewayFragment from './gateway/fragment.generated'
import { GatewayGeneratedTypePolicies } from './gateway/react-type-policies.generated'
import { TypedTypePolicies } from './gateway/react.generated'
import { IconError } from './icons'
import './index.css'
import { createAppInsightsInstance } from './lib/appInsights/client'
import { AppInsightsProvider } from './lib/appInsights/context'
import './lib/translations/i18n'
import { Splash } from './splash'
import {
  serviceWorkerInitialized,
  serviceWorkerUpdated
} from './state/actions/app/serviceWorker'
import { StateSettings, getInstrumentationKey } from './state/settings'
import { persistor, store } from './state/store'
import * as serviceWorker from './serviceWorker'

import * as OfflinePluginRuntime from 'offline-plugin/runtime'
OfflinePluginRuntime.install({
  onInstalled () {
    OfflinePluginRuntime.update()
  }
})

//const loginUrl = getLogin()
const instrumentationKey = getInstrumentationKey()


//console.log("loginUrl",loginUrl)
//console.log("instrumentationKey",instrumentationKey)


const documentReady = () => {
  return new Promise<void>(resolve => {
    if (/complete|interactive/.test(document.readyState)) {
      resolve()
    } else {
      window.addEventListener(`DOMContentLoaded`, () => resolve())
    }
  })
}

const LazyAppRoot = React.lazy(() =>
  import(`./app/root`).then(x => ({ default: x.AppRoot }))
)

const startup = async () => {
  enableMapSet()

  /*
  const appInsights = createClient(
    `https://api-dev.agriware.cloud/appinsights/v2/track` // doesnt this need to be dynamic??
  )
  */
  const appInsights = createAppInsightsInstance( instrumentationKey! )


  await documentReady()

  const root = document.getElementById('root')
  if (!root) {
    throw Error(`root element missing`)
  }

  await render(
    <React.StrictMode>
      <MpriseTheme />
      <MpriseMuiThemeProvider>
        <ErrorBoundary>
          <Suspense fallback={<Splash />}>
            <NoSsr>
              <BrowserRouter>
                <AppInsightsProvider appInsights={appInsights}>
                  <StateSettings>
                    <PersistGate loading={<Splash />} persistor={persistor}>
                      <Provider store={store}>
                        <Root />
                      </Provider>
                    </PersistGate>
                  </StateSettings>
                </AppInsightsProvider>
              </BrowserRouter>
            </NoSsr>
          </Suspense>
        </ErrorBoundary>
      </MpriseMuiThemeProvider>
    </React.StrictMode>,
    root
  )

  function render (element: any, container: HTMLElement) {
    return new Promise<void>(r => ReactDOM.render(element, container, r))
  }
}

const typePolicies: TypedTypePolicies = {
  ...(GatewayGeneratedTypePolicies as any),
  Query: {
    fields: {
      searchWorkItems: {
        keyArgs: [
          `query`,
          `companyId`,
          `resourceIds`,
          `createdByResourceIds`,
          `ownerResourceIds`,
          `taskType`,
          `status`,
          `priority`,
          `assignment`,
          `positionIds`,
          `startDate`,
          `endDate`,
          `removed`
        ],
        merge (existing, incoming, { variables }) {
          if (variables && variables.offset === 0) {
            return incoming
          } else if (existing && existing.workItems) {
            const workItems = (existing.workItems ?? []).concat(
              incoming.workItems ?? []
            )
            return { ...incoming, workItems }
          } else {
            return incoming
          }
        }
      },
      searchRpgWorkItems: {
        keyArgs: [
          `query`,
          `assigned`,
          `unAssigned`,
          `pending`,
          `team`,
          `positionIds`,
          `startDate`,
          `endDate`
        ]
      }
    }
  }
}

const schema = buildClientSchema(require(`./gateway/gateway.schema.json`))

const Root = () => {
  const settings = useContext(StateSettings.Context)
  const [mpriseId] = useState(
    () => new MpriseIdentityServer(settings.login, settings.clientId)
  )
  return (
    <MpriseAuthProvider mpriseId={mpriseId}>
      <MpriseGatewayApolloClient
        batch={true}
        schema={schema}
        gatewayUrl={settings.gateway}
        typePolicies={typePolicies}
        possibleTypes={GatewayFragment.possibleTypes}
      >
        <Alerts>
          <LazyAppRoot />
        </Alerts>
      </MpriseGatewayApolloClient>
    </MpriseAuthProvider>
  )
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { error?: Error; errorInfo?: ErrorInfo }
> {
  static clearState () {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `/`
  }

  static refresh () {
    window.location.reload()
  }

  constructor (props: { children: React.ReactNode }) {
    super(props)

    this.state = {}
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    console.error(`[error-boundary]`, { error, errorInfo })
    this.setState({ error, errorInfo })
  }

  render () {
    if (this.state.error) {
      return (
        <>
          <AppBar>
            <Toolbar>
              <Typography variant='subtitle1'>Oops!</Typography>
            </Toolbar>
          </AppBar>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            margin='8rem 2rem 2rem 2rem'
          >
            <IconError color='disabled' style={{ fontSize: `5rem` }} />
            <Typography variant='subtitle2'>An error occurred</Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            margin='4rem'
            style={{ gap: `2rem` }}
          >
            <Button
              color='secondary'
              variant='contained'
              onClick={ErrorBoundary.clearState}
            >
              Clear state
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={ErrorBoundary.refresh}
            >
              Reload
            </Button>
          </Box>
          <Box display='flex' flexDirection='column' margin='4rem' width='auto'>
            <Typography variant='subtitle2'>Error details:</Typography>
            <MText block>
              <span>{this.state.error.message}</span>
            </MText>
          </Box>
        </>
      )
    } else {
      return this.props.children
    }
  }
}

const startTime = Date.now()
startup()
  .then(() => {
    const duration = Date.now() - startTime
    console.log(`Initialized in ${(duration / 1000).toFixed(1)}s`)
  })
  .catch(error => {
    console.error(`Startup error:`, error)
    const root = document.getElementById('root')
    if (root) {
      root.innerText = `Oops! ${String(error.message ?? error)}`
    }
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch(serviceWorkerInitialized()),
  onUpdate: reg => store.dispatch(serviceWorkerUpdated(reg))
})
